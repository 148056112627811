import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./screens/main";
import './App.css';
import Products from "./screens/products";
import BrassPin from "./screens/list/BrassPin";
import CopperPin from "./screens/list/CopperPin";
import AluminiumPin from "./screens/list/AluminiumPin";
import BanjoBolts from "./screens/list/BanjoBolts";
import CopperWasher from "./screens/list/copperWasher";
import NylonBush from "./screens/list/nylonBush";
import TerminalPin from "./screens/list/terminalPin";
import AboutUsPage from "./screens/list/AboutUs";
import ContactUsPage from "./screens/list/ContactUs";


const App = () => {
  return (
    <div className='mainapp'>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/products" element={<Products />} />
          {/* <Route path="/about" element={<AboutUsPage />} /> */}
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/brass-micro-pin-terminal-pins-electrical-connector-pin-manufacturer-in-birmingham-united-kingdom-uk" element={<BrassPin />} />
          <Route path="/electrical-copper-pin-terminals-copper-connector-pin-bespoke-contact-pins-manufacturer-in-uk" element={<CopperPin />} />
          <Route path="/aluminium-pin-terminal-connector-pins-manufacturer-in-uk" element={<AluminiumPin />} />
          <Route path="/hydraulics-banjo-bolts-manufacturer-ss-banjo-bolt-supplier-aluminium-banjo-in-uk" element={<BanjoBolts />} />
          <Route path="/copper-washer-crush-washers-plumbing-washers-copper-gasket-hvac-washers-manufacturer-in-uk" element={<CopperWasher />} />
          <Route path="/nylon-bushes-bearings-nylone-bushings-flanged-bearning-manufacturer-in-uk" element={<NylonBush />} />
          <Route path="/terminal-pin-terminal-connector-pins-manufacturer-in-uk" element={<TerminalPin />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
