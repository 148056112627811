import React from "react";
import Typography from "@mui/material/Typography";

const Footer = ({ styles }) => {
    return (
        <footer
            style={{
                backgroundColor: "#E7888D",
                padding: "20px",
                textAlign: "center",
                color: "#fff",
                position: "fixed",
                bottom: 0,
                width: "100%",
                ...styles, // Apply additional styles
            }}
        >
            <Typography variant="body2" color="inherit">
                © 2024 Darlaston Smith Ltd All rights reserved.
            </Typography>
        </footer>
    );
};

export default Footer;
