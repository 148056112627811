// AboutUsPage.js
import React from 'react';
import styled from 'styled-components';
import Header from '../../components/header';
import Footer from '../../components/footer';
import './aboutus.css'
const AboutSectionContainer = styled.section`
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f4f4f4;
`;
const TeamMemberCard = ({ name, role, description, email }) => (
    <div className="column">
        <div className="card">
            <img src={`/w3images/${name.toLowerCase()}.jpg`} alt={name} style={{ width: '100%' }} />
            <div className="container">
                <h2>{name}</h2>
                <p className="title">{role}</p>
                <p>{description}</p>
                <p>{email}</p>
                <p>
                    <button className="button">Contact</button>
                </p>
            </div>
        </div>
    </div>
);


const AboutUsPage = () => {
    return (
        <div>
            <Header />
            <div>
                <div className="about-section">
                    <h1>About Us Page</h1>
                    <p>Some text about who we are and what we do.</p>
                    <p>Resize the browser window to see that this page is responsive, by the way.</p>
                </div>

                <h2 style={{ textAlign: 'center' }}>Our Team</h2>
                <div className="row">
                    <TeamMemberCard name="Jane Doe" role="CEO & Founder" description="Some text that describes me lorem ipsum ipsum lorem." email="jane@example.com" />
                    <TeamMemberCard name="Mike Ross" role="Art Director" description="Some text that describes me lorem ipsum ipsum lorem." email="mike@example.com" />
                    <TeamMemberCard name="John Doe" role="Designer" description="Some text that describes me lorem ipsum ipsum lorem." email="john@example.com" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AboutUsPage;
