import React from 'react';
import './Category.css';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Image1 from '../../images/banjo-bolts-manufacturer-in-uk-hydraulics-banjo-bolts-supplier.webp.webp';
import Image2 from '../../images/performance-banjo-bolts-supplier-brake-banjo-bolt-manufacturer.webp.webp';
import Image3 from '../../images/hydraulic-system-bolts-high-pressure-banjo-bolts-steel-hydrulic-banjo-bolts.webp.webp';
import Image4 from '../../images/precision-hydraulics-bolts-hydraulic-hose-bolts-manufacturer-in-uk.webp.webp';
import Image5 from '../../images/bulk-hydraulic-banjo-bolts-coupling-bolts-hydraulic-connector-bolts.webp.webp';
import Image6 from '../../images/hydraulics-bolts-supplier-uk-automobile-banjo-bolts-union-manufacturer.webp.webp';
import Image7 from '../../images/automotive-hydraulics-banjo-bolts-connector-bolts.webp.webp';
import Image8 from '../../images/Double hydraulic-cylinder-bolt-manufacturer-uk.webp.webp';

const TerminalPin = () => {
    const dummyCategories = [
        { name: 'Brass Pin', image: 'https://placekitten.com/100/100', link: 'brass-micro-pin-terminal-pins-electrical-connector-pin-manufacturer-in-birmingham-united-kingdom-uk' },
        { name: 'Aluminium Pin', image: 'https://placekitten.com/102/100', link: 'aluminium-pin-terminal-connector-pins-manufacturer-in-uk' },
        { name: 'Copper Pin', image: 'https://placekitten.com/103/100', link: 'electrical-copper-pin-terminals-copper-connector-pin-bespoke-contact-pins-manufacturer-in-uk' },
        { name: 'Copper Washer', image: 'https://placekitten.com/103/100', link: 'copper-washer-crush-washers-plumbing-washers-copper-gasket-hvac-washers-manufacturer-in-uk' },
        { name: 'Terminal Pin', image: 'https://placekitten.com/103/100', link: 'terminal-pin-terminal-connector-pins-manufacturer-in-uk' },
        { name: 'Nylon Bush', image: 'https://placekitten.com/103/100', link: 'nylon-bushes-bearings-nylone-bushings-flanged-bearning-manufacturer-in-uk' },
        // Add more categories as needed
    ];
    return (
        <div>
            <Header />
            <div className='button-grid'>
                {dummyCategories.map((category, index) => (
                    <Link key={category.name} to={`/${category.link}`} >
                        <button className='button CategoryButton'>{category.name}</button>
                    </Link>
                ))}
            </div>
            <div className='Main'>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={Image1} alt='hydraulics banjo bolt made of steel for high pressure hydraulics system' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={Image2} alt='Banjo bolts for performance automobile application' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={Image3} alt='Precision Industrial Hydraulics Banjo Bolts Fittings' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={Image4} alt='Precision hydraulics bolts and hydraulic hose bolts - Leading manufacturer in the UK' />
                    </div>
                </div>
                <div className='MainDiv3'>
                    <div>
                        Welcome to Darlaston Smith Ltd, your trusted source for high-quality banjo bolts and precision-engineered banjo unions. As a key player in the industry, our focus on precision engineering and exceptional quality positions us as a reliable source for all your banjo bolts manufacturer needs, offering top-notch solutions for fluid system connections. Our commitment to excellence and decades of expertise make us a leading choice in the industry. We specialize in manufacturing high-quality banjo bolts and unions to meet all your unique banjo bolt requirements, especially for hydraulic, brake, fuel, or cooling systems.
                        Our Banjo Bolts:
                        We take pride in offering a wide selection of banjo bolts to fulfill your needs. From stainless steel banjo bolts to brass banjo bolts, we have you covered. Our banjo bolts are meticulously crafted to ensure optimal performance and unparalleled reliability. Whether you need banjo bolts for automotive, marine, or industrial applications, we have the perfect banjo bolt solution for you.
                        At Darlaston Smith Ltd, we understand the importance of precision in banjo bolt manufacturing. That's why all our banjo bolts undergo rigorous quality control measures to guarantee flawless performance. Our skilled engineers use advanced techniques to produce banjo bolts that meet and exceed industry standards. With our wide range of banjo bolt sizes, you can rest assured knowing that your systems are secured with the utmost care and expertise.
                        In addition to our outstanding banjo bolt quality, we also offer competitive pricing to maximize value for our customers. We firmly believe that high-quality banjo bolts shouldn't break the bank, which is why we strive to provide affordable options without compromising on performance or durability. With Darlaston Smith Ltd, you can experience the perfect balance of quality, reliability, and cost-effectiveness.
                        When it comes to brake banjo bolts or hydraulics banjo bolts trust the experts at Darlaston Smith Ltd. With our extensive experience and unwavering dedication to customer satisfaction, we have established ourselves as a trusted name in the industry. Whether you need standard banjo bolts or customized solutions, we are here to assist you every step of the way. Contact us today to discuss your banjo bolt requirements and let us exceed your expectations.
                        Discover unparalleled performance with our range of banjo bolts meticulously crafted to meet the highest standards. Engineered with precision and durability in mind, our banjo bolts are designed to excel in demanding applications across automotive, hydraulic, and industrial systems.

                    </div>
                </div>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={Image5} alt='Bulk head Hydraulic Banjo Bolts - Coupling Bolts - Hydraulic Connector Bolts for Efficient Industrial Connections' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={Image6} alt='Hydraulics Bolts Supplier in the UK - Specializing in Automobile Banjo Bolts and Union Manufacturing' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={Image7} alt='Automotive Hydraulics - High-Quality Banjo Bolts and Connector Bolts for Efficient Performance.' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={Image8} alt='Double Hydraulic Cylinder Bolt Manufacturer in the UK - Precision-engineered bolts for reliable industrial applications.' />
                    </div>
                </div>
            </div>
            <div className='MainDiv5'>
                <div className='insideDiv2'>
                    <img src={Image1} alt='' />
                </div>
                <div className='insideDiv3'>
                    <img src={Image2} alt='' />
                </div>
                <div className='insideDiv4'>
                    <img src={Image3} alt='' />
                </div>
            </div>
            <Footer />
        </div>
    );
};


export default TerminalPin;