import React from 'react';
import './Category.css';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Image1 from '../../images/1-nylon-bushings-nylon-bearings-plastic-bushings.webp';
import Image2 from '../../images/2-Self-lubricating-nylon-bushings-High-performance-nylon-bushes.webp';
import Image3 from '../../images/3-Low-friction-plastic-bearings-durable-nylon-bushings.webp';
import Image4 from '../../images/4-wear-resistant-plastic-bearings-corrosion-resistant-nylon-bushes.webp';
import Image5 from '../../images/5-insulating-nylon-bearings-lightweight-plastic-bushings-Noise-reducing-nylon-bearings.webp';
import Image6 from '../../images/6-industrial-nylon-bushings-automotive-nylon-bearings-nylon-pivot-bushings.webp';
import Image7 from '../../images/7-nylon-sleeve-bearings-precision-nylon-bushings-nylon-plain-bearings.webp';
import Image8 from '../../images/8-custom-nylon-bearings-nylon-thrust-bearings-nylon-flange-bushings.webp';

const NylonBush = () => {
    const dummyCategories = [
        { name: 'Brass Pin', image: 'https://placekitten.com/100/100', link: 'brass-micro-pin-terminal-pins-electrical-connector-pin-manufacturer-in-birmingham-united-kingdom-uk' },
        { name: 'Aluminium Pin', image: 'https://placekitten.com/102/100', link: 'aluminium-pin-terminal-connector-pins-manufacturer-in-uk' },
        { name: 'Banjo Bolts', image: 'https://placekitten.com/103/100', link: 'hydraulics-banjo-bolts-manufacturer-ss-banjo-bolt-supplier-aluminium-banjo-in-uk' },
        { name: 'Copper Pin', image: 'https://placekitten.com/103/100', link: 'electrical-copper-pin-terminals-copper-connector-pin-bespoke-contact-pins-manufacturer-in-uk' },
        { name: 'Copper Washer', image: 'https://placekitten.com/103/100', link: 'copper-washer-crush-washers-plumbing-washers-copper-gasket-hvac-washers-manufacturer-in-uk' },
        { name: 'Terminal Pin', image: 'https://placekitten.com/103/100', link: 'terminal-pin-terminal-connector-pins-manufacturer-in-uk' },

        // Add more categories as needed
    ];
    return (
        <div>
            <Header />
            <div className='button-grid'>
                {dummyCategories.map((category, index) => (
                    <Link key={category.name} to={`/${category.link}`} >
                        <button className='button CategoryButton'>{category.name}</button>
                    </Link>
                ))}
            </div>
            <div className='Main'>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={Image1} alt='High-quality nylon bushings, durable nylon bearings, and versatile plastic bushings for industrial applications' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={Image2} alt='elf-lubricating nylon bushings - Explore our range of high-performance nylon bushes for superior industrial applications' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={Image3} alt='Low-friction plastic bearings - Discover the durability of our advanced nylon bushings for reliable industrial applications' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={Image4} alt='Wear-resistant plastic bearings - Explore the reliability of our corrosion-resistant nylon bushes for long-lasting industrial performance' />
                    </div>
                </div>
                <div className='MainDiv3'>
                    <div>
                        Introducing Darlasotn Smith Ltd, your reliable partner for all your nylon bushings manufacturing needs. We specialize in creating top-notch nylon bushings that are durable, smooth, and wear-resistant. These high-performance nylon bearings, also known as nylon roller bearings, are perfect for industries like machinery, automotive, and more.
                        At Darlasotn Smith Ltd, we take pride in our wide range of nylon bushings. Whether you need flanged nylon bushings, nylon rollers with bearings, or nylon spacer bushings, we have got you covered. Our nylon sleeve bearings and nylon flanged bushings are designed with utmost precision to meet your specific requirements.
                        When it comes to quality, Darlasotn Smith Ltd never compromises. We understand the importance of reliable and long-lasting nylon bearings for your machinery and automotive applications. Our expertise in manufacturing durable nylon bushings ensures that you receive products that are of the highest standard.
                        Partner with us for all your nylon bushing needs and experience the difference that Darlasotn Smith Ltd brings to the table. Contact us today to discuss your requirements and let our team assist you in finding the perfect solutions for your industry.
                        Our nylon bushes, also known as nylon bearings or Nylon thrust bearings and nylon bush bearings, are built to last in challenging environments. We offer a wide range of options, including self-lubricating bushings, low-friction plastic bearings and corrosion-resistant varieties, to meet your specific requirements.
                        We understand that every client is unique, which is why we provide custom nylon bushing solutions tailored to your needs. Our product line includes precision bearings and electrically insulating options to ensure the perfect fit for your project.
                        With years of experience, we have built a reputation for quality, innovation, and customer satisfaction. Discover the Darlasotn Smith Ltd difference by exploring our extensive range of flanged nylon bush, nylon sleeve bearings and precision nylon bushings. Whether you need lightweight, noise-reducing options or high-temperature bearings, we have the solution for you.
                        Get in touch with us today to discuss your requirements and find the ideal nylon bushing for your application. Trust Darlasotn Smith Ltd to deliver cutting-edge nylon bearing solutions that excel in your projects.
                    </div>
                </div>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={Image5} alt='nsulating nylon bearings - Lightweight plastic bushings - Experience noise reduction with our innovative nylon bearings.' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={Image6} alt='Industrial nylon bushings - Explore automotive-grade nylon bearings and precision-engineered nylon pivot bushings for reliable performance.' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={Image7} alt='Nylon sleeve bearings - Precision-engineered nylon bushings - High-performance nylon plain bearings for diverse industrial applications.' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={Image8} alt='Custom nylon bearings - Explore precision-engineered nylon thrust bearings and versatile nylon flange bushings for tailored industrial solutions. ' />
                    </div>
                </div>
            </div>
            <div className='MainDiv5'>
                <div className='insideDiv2'>
                    <img src={Image1} alt='' />
                </div>
                <div className='insideDiv3'>
                    <img src={Image2} alt='' />
                </div>
                <div className='insideDiv4'>
                    <img src={Image3} alt='' />
                </div>
            </div>
            <Footer />
        </div>
    );
};


export default NylonBush;