import React, { useState } from 'react';
import Categories from './category';
import Header from '../components/header';
import Footer from '../components/footer';
import './products.css'
const Products = () => {

    return (
        <div className='products'>
            <Header />
            <div className='categories'>
                <Categories />
            </div>
            <Footer />
        </div>
    )
}

export default Products
