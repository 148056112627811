import React, { useState } from 'react';
import './main.css';
import Header from '../components/header';
import BackgroundImage from '../images/pics01.jpg'
import Divider from '@mui/material/Divider';
import Categories from './category';
import Footer from '../components/footer';
import SlideShow from '../components/slideShow';
const Main = () => {
    const [submenuVisible, setSubmenuVisible] = useState(false);
    const [nestedSubmenuVisible, setNestedSubmenuVisible] = useState(false);

    const handleOurProductsClick = (e) => {
        e.preventDefault();
        setSubmenuVisible(!submenuVisible);
    };

    const handleSubmenuItem = (e) => {
        e.preventDefault();
        setNestedSubmenuVisible(!nestedSubmenuVisible);
    };

    return (
        <div className='main'>
            <Header />
            <div className='mainImage'><img src={BackgroundImage} alt=""></img></div>
            <div className="container">
                <h1>Welcome to Darlaston Smith Ltd: Pioneers in Precision Electrical Components</h1>
                <p>
                    At Darlaston Smith Ltd, we specialize in crafting the finest electrical components that power your innovations. Our extensive product range, including Brass Pins, Copper Pins, Terminals, Electrical Connectors, and Micro Pins, is engineered with precision and coated with gold and tin for unparalleled conductivity.
                </p>
                <h3>Unmatched Quality in Brass and Copper Pins</h3>
                <p>
                    Our Brass Pins and Copper Pins stand at the forefront of electrical connectivity. Recognized for their durability and exceptional electrical conductivity, these pins are essential in a wide array of electrical applications. Darlaston Smith Ltd ensures each pin is meticulously manufactured, meeting the highest industry standards.
                </p>
                <h3>Advanced Terminals for Secure Connections</h3>
                <p>
                    In the realm of electrical engineering, the importance of reliable terminals cannot be overstated. Our terminals are designed for secure, long-lasting connections, supporting a range of electrical systems. Trust Darlaston Smith Ltd for terminals that are not just components but cornerstones of electrical reliability.
                </p>
                <h3>Electrical Connectors: The Link to Efficient Power</h3>
                <p>
                    Connectivity is key in the electrical world, and our Electrical Connectors are crafted to ensure seamless power transmission. Suitable for various applications, they are the go-to choice for professionals seeking reliability and efficiency.
                </p>
                <h3>Micro Pins: Small in Size, Big on Performance</h3>
                <p>
                    Our Micro Pins are a testament to our commitment to precision engineering. Ideal for intricate electronic applications, these pins are designed for high-performance and reliability, making them perfect for the most demanding of tasks.
                </p>
                <h3>Gold and Tin Plating: Enhancing Conductivity</h3>
                <p>
                    At Darlaston Smith Ltd, we go a step further with our gold and tin plating process. This not only enhances the conductivity of our components but also adds a layer of corrosion resistance, ensuring longevity and consistent performance.
                </p>
                <h3>
                    Why Choose Darlaston Smith Ltd?
                </h3>
                <p>
                    Expertise: Decades of experience in manufacturing high-quality electrical components.
                    Quality Assurance: Rigorous testing and quality control for every product.
                    Custom Solutions: Tailored products to meet specific client needs.
                    Sustainability Commitment: Environmentally responsible manufacturing processes.
                </p>
                <h3>Connect with Us for Your Electrical Component Needs</h3>
                <p>
                    Experience the difference in quality and performance with Darlaston Smith Ltd. For inquiries or to discuss your specific requirements, contact us today. Power your projects with precision-engineered electrical components from Darlaston Smith Ltd – where quality and innovation connect.
                </p>
            </div>
            <div className='diveder'>
                <Divider variant="middle" />
            </div>
            <Categories />
            <div className='diveder'>
                <Divider variant="middle" />
            </div>
            <SlideShow />
            <div className='diveder'>
                <Divider variant="middle" />
            </div>
            <Footer />
        </div>
    );
};

export default Main;
