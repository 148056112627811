import React from 'react';
import './category.css';
import { Link } from 'react-router-dom';
import ImagebrassPin1 from '../images/high-quality-pcb-brass-terminal-pins-electrical-application.webp'
import Image1 from '../images/aluminium-industrial-pins-plunger-pin-manufacturer-in-uk.webp';
import Image2 from '../images/copper-connector-pin-pcb-pin-terminal-pins-electrical-pin-connectors-darlaston-smith-ltd.webp.webp';
import Image3 from '../images/hydraulic-system-bolts-high-pressure-banjo-bolts-steel-hydrulic-banjo-bolts.webp.webp';
import Image4 from '../images/4-Copper-gasket-washer-hvac-washers-supplier-in-uk-bespoke-supplier.webp';
import Image5 from '../images/brass-terminal-pin-supplier-in-birmingham-turret-terminal-pin.webp';
import Image6 from '../images/7-nylon-sleeve-bearings-precision-nylon-bushings-nylon-plain-bearings.webp';

const dummyCategories = [
    { name: 'Brass Pin', image: ImagebrassPin1, link: 'brass-micro-pin-terminal-pins-electrical-connector-pin-manufacturer-in-birmingham-united-kingdom-uk' },
    { name: 'Copper Pin', image: Image1, link: 'electrical-copper-pin-terminals-copper-connector-pin-bespoke-contact-pins-manufacturer-in-uk' },
    { name: 'Aluminium Pin', image: Image2, link: 'aluminium-pin-terminal-connector-pins-manufacturer-in-uk' },
    { name: 'Banjo Bolts', image: Image3, link: 'hydraulics-banjo-bolts-manufacturer-ss-banjo-bolt-supplier-aluminium-banjo-in-uk' },
    { name: 'Terminal Pin', image: Image5, link: 'terminal-pin-terminal-connector-pins-manufacturer-in-uk' },
    { name: 'Nylon Bush', image: Image6, link: 'nylon-bushes-bearings-nylone-bushings-flanged-bearning-manufacturer-in-uk' },
    { name: 'Copper Washer', image: Image4, link: 'copper-washer-crush-washers-plumbing-washers-copper-gasket-hvac-washers-manufacturer-in-uk' },
    { name: 'Copper Washer', image: Image4, link: 'copper-washer-crush-washers-plumbing-washers-copper-gasket-hvac-washers-manufacturer-in-uk' },
    // { name: 'Category 5', image: 'https://placekitten.com/100/100' },
    // { name: 'Category 6', image: 'https://placekitten.com/101/100' },
    // { name: 'Category 7', image: 'https://placekitten.com/102/100' },
    // { name: 'Category 8', image: 'https://placekitten.com/103/100' },
    // { name: 'Category 9', image: 'https://placekitten.com/100/100' },
    // { name: 'Category 10', image: 'https://placekitten.com/101/100' },
    // { name: 'Category 11', image: 'https://placekitten.com/102/100' },
    // { name: 'Category 12', image: 'https://placekitten.com/103/100' },
    // { name: 'Category 13', image: 'https://placekitten.com/100/100' },
    // { name: 'Category 14', image: 'https://placekitten.com/101/100' },
    // { name: 'Category 15', image: 'https://placekitten.com/102/100' },
    // { name: 'Category 16', image: 'https://placekitten.com/103/100' },
    // Add more categories as needed
];

const Categories = () => {
    return (
        <div className="categories-container">
            {dummyCategories.map((category, index) => (
                <Link key={category.name} to={`/${category.link}`} className="category-item">
                    {/* Use /list/${category.name} as the to prop */}
                    <img src={category.image} alt={category.name} />
                    <p>{category.name}</p>
                </Link>
            ))}
        </div>
    );
};

export default Categories;