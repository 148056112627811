import React from 'react';
import './Category.css';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Image1 from '../../images/electrical-connectors-wire-terminal-pins-crimped-pin-manufacturer-in-uk.webp';
import Image2 from '../../images/turret-terminal-pin-connector-crimp-terminal-pins-electrical-connection-pins.webp';
import Image3 from '../../images/pin-terminal-connectors-automotive-terminal-pin-kits-wire-connectors-manufacturer-in-uk.webp';
import Image4 from '../../images/terminal-connector-pin-screw-terminal-kits-manufacturer-in-uk.webp';
import Image5 from '../../images/brass-terminal-pin-supplier-in-birmingham-turret-terminal-pin.webp';
import Image6 from '../../images/Precision-Terminal-Pins-Connector-Crimping-Electrical-Connection-Automotive-Kits-Wire-Connectors-UK-Manufacturer.webp';
import Image7 from '../../images/Terminal-Pins-Connector-Crimping-Electrical-Connection-Automotive-Kits-Wire-Connectors.webp';
import Image8 from '../../images/Precision-Pins-Lug-Wire-terminal-pins-Electrical-connection-pins-Manufacturer.webp';

const TerminalPin = () => {
    const dummyCategories = [
        { name: 'Brass Pin', image: 'https://placekitten.com/100/100', link: 'brass-micro-pin-terminal-pins-electrical-connector-pin-manufacturer-in-birmingham-united-kingdom-uk' },
        { name: 'Aluminium Pin', image: 'https://placekitten.com/102/100', link: 'aluminium-pin-terminal-connector-pins-manufacturer-in-uk' },
        { name: 'Banjo Bolts', image: 'https://placekitten.com/103/100', link: 'hydraulics-banjo-bolts-manufacturer-ss-banjo-bolt-supplier-aluminium-banjo-in-uk' },
        { name: 'Copper Pin', image: 'https://placekitten.com/103/100', link: 'electrical-copper-pin-terminals-copper-connector-pin-bespoke-contact-pins-manufacturer-in-uk' },
        { name: 'Copper Washer', image: 'https://placekitten.com/103/100', link: 'copper-washer-crush-washers-plumbing-washers-copper-gasket-hvac-washers-manufacturer-in-uk' },
        { name: 'Nylon Bush', image: 'https://placekitten.com/103/100', link: 'nylon-bushes-bearings-nylone-bushings-flanged-bearning-manufacturer-in-uk' },
        // Add more categories as needed
    ];
    return (
        <div>
            <Header />
            <div className='button-grid'>
                {dummyCategories.map((category, index) => (
                    <Link key={category.name} to={`/${category.link}`} >
                        <button className='button CategoryButton'>{category.name}</button>
                    </Link>
                ))}
            </div>
            <div className='Main'>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={Image1} alt='Close-up of turret terminal pin connector with crimped ends, illustrating precision electrical connection pins for versatile applications' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={Image2} alt='High-quality pin terminal connectors for automotive use, part of our wire connector kits. Leading manufacturer in the UK for reliable electrical solutions' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={Image3} alt='Close-up view of a terminal lug pin turret, showcasing a precision-engineered component for electrical connections with durability and reliability' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={Image4} alt='Close-up view of turret terminal pin connector with crimped ends, showcasing precision electrical connection pins. The image features a detailed look at the construction and design of the electrical terminal pins, highlighting their quality and suitability for various applications' />
                    </div>
                </div>
                <div className='MainDiv3'>
                    <div>
                        As a leading supplier of terminal pins, including pin terminal connectors, we specialize in crafting high-quality terminal pins that cater to the unique needs of various industries. Our extensive range of terminal pins, also known as pin terminals or pin terminal connectors, are designed and engineered to ensure optimal conductivity, durability, and reliability. With our expertise in terminal lugs pin type, we guarantee top-notch performance and functionality for all your electrical connection requirements. You can trust us for the finest quality pin terminal connectors, including automotive terminal pin kits, available in the market.
                        At Darlaston Smith Ltd, we offer a wide selection of wire connectors terminal pins tailored to meet the strict requirements of applications spanning automotive, electronics, telecommunications, and more. Whether you need wiring terminals pin for connections or intricate electronic assemblies, our expertise in pin terminal connectors ensures solutions that exceed industry standards. With our comprehensive selection of connector pins terminal options, including automotive terminal pin kits, you can find the perfect terminal pin for any project. Our team of experts is dedicated to providing top-quality pin terminal products, including pin terminal connectors, screw terminal and pin terminal connector kits, that meet your exact specifications.
                        Our commitment to excellence extends to the manufacturing of male connector terminal pins with various configurations, including round, small, female connector, ferrule terminal, crimp connectors and close-tolerance wire constructions. These turret terminal pin, whether in a pin terminal connector or not, are specifically designed to facilitate low insertion force or high-density connection terminals, ensuring seamless integration into a wide array of devices.
                        Enhance your projects with terminal lug pins, turret terminal pin, including pin terminal connectors, wire termination and pin terminal connector kits, that set the benchmark for unparalleled quality and performance. Contact us today to explore our comprehensive range, including automotive terminal pin kits, and discover how our precision-engineered connection terminal pins can power your innovations.
                    </div>
                </div>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={Image5} alt='Close-up of a precision-engineered turret terminal pin connector with crimped ends, exemplifying excellence in electrical connection pins' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={Image6} alt='' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={Image7} alt='' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={Image8} alt='' />
                    </div>
                </div>
            </div>
            <div className='MainDiv5'>
                <div className='insideDiv2'>
                    <img src={Image1} alt='' />
                </div>
                <div className='insideDiv3'>
                    <img src={Image2} alt='' />
                </div>
                <div className='insideDiv4'>
                    <img src={Image3} alt='' />
                </div>
            </div>
            <Footer />
        </div>
    );
};


export default TerminalPin;