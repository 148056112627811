import React from 'react';
import './Category.css';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Image1 from '../../images/copper-micro-pin-copper-electrical-pin-terminal-pin-manufacturer-in-uk.jpg.webp';
import Image2 from '../../images/copper-connector-pin-pcb-pin-terminal-pins-electrical-pin-connectors-darlaston-smith-ltd.webp.webp';
import Image3 from '../../images/electrical-connnector-pin-terminal-pin-connector-connecting-terminal-darlaston-smith-ltd.webp.webp';
import Image4 from '../../images/copper-pin-micro-pin-connector-terminal-pin-type-lug-pin-connnector-pin.webp.webp';
import Image5 from '../../images/copper-electrical-contact-pin-socket-pin-micro-pin-connector-manufacturer-in-uk.webp.webp';
import Image6 from '../../images/copper-contact-pin-connecting-terminals-terminal-connectors-pcb-terminal-pin.webp.webp';
import Image7 from '../../images/copper-pin connectors-pin-terminal-electrical-pin-connectorpin-terminals.webp.webp';
import Image8 from '../../images/copper-pin-terminal-connector-electric-terminals.webp';

const CopperPin = () => {
    const dummyCategories = [
        { name: 'Brass Pin', image: 'https://placekitten.com/100/100', link: 'brass-micro-pin-terminal-pins-electrical-connector-pin-manufacturer-in-birmingham-united-kingdom-uk' },
        { name: 'Aluminium Pin', image: 'https://placekitten.com/102/100', link: 'aluminium-pin-terminal-connector-pins-manufacturer-in-uk' },
        { name: 'Banjo Bolts', image: 'https://placekitten.com/103/100', link: 'hydraulics-banjo-bolts-manufacturer-ss-banjo-bolt-supplier-aluminium-banjo-in-uk' },
        { name: 'Copper Washer', image: 'https://placekitten.com/103/100', link: 'copper-washer-crush-washers-plumbing-washers-copper-gasket-hvac-washers-manufacturer-in-uk' },
        { name: 'Terminal Pin', image: 'https://placekitten.com/103/100', link: 'terminal-pin-terminal-connector-pins-manufacturer-in-uk' },
        { name: 'Nylon Bush', image: 'https://placekitten.com/103/100', link: 'nylon-bushes-bearings-nylone-bushings-flanged-bearning-manufacturer-in-uk' },
        // Add more categories as needed
    ];
    return (
        <div>
            <Header />
            <div className='button-grid'>
                {dummyCategories.map((category, index) => (
                    <Link key={category.name} to={`/${category.link}`} >
                        <button className='button CategoryButton'>{category.name}</button>
                    </Link>
                ))}
            </div>
            <div className='Main'>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={Image1} alt='Electroplated copper pin connectors and electrical contact pins with insulated pin terminals for corrosion resistance and safety.' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={Image2} alt='A variety of copper pin connectors with different pin terminal sizes and types, suitable for various electrical applications.' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={Image3} alt='Crimp-on copper pin connectors and terminals for quick and easy electrical assembly' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={Image4} alt='High-quality copper pin terminals with secure connector pin terminals, ideal for reliable electrical connections.' />
                    </div>
                </div>
                <div className='MainDiv3'>
                    <div>
                        Copper Pin Terminals and Connectors
                        We are a well-established and reputable company specializing in the production and manufacturing of high-quality copper pin terminal connectors. With years of experience in the industry, we have gained a strong reputation for our expertise and commitment to delivering top-notch copper pin products to our customers. Our team of skilled professionals utilizes state-of-the-art technology and advanced manufacturing processes to ensure the utmost precision and durability of our copper pin terminal connectors or copper lug terminal.
                        We understand the importance of reliable and efficient electrical copper pin connections, which is why we prioritize the quality and performance of our copper pin terminal connectors. Whether you are in need of copper pin terminal connectors for industrial applications or residential projects, we have a wide range of options to meet your specific requirements. Our copper terminal pin connectors, including copper pin crimp type lugs, copper pin connector, copper pins terminals, are designed to provide secure and stable connections, minimizing the risk of electrical failures or disruptions. Additionally, we offer bespoke solutions to cater to unique project needs, ensuring that our copper pin terminal connectors seamlessly integrate into your electrical systems. Customer satisfaction is at the core of our business, and we strive to exceed expectations with our exceptional copper pin products and reliable service. When you choose our copper pin terminal connectors, you can trust that you are investing in a reliable and long-lasting solution for your electrical needs.
                        For any requirement of bespoke copper pin terminals connectors, copper pin connector, or copper pin type lugs, please feel free to drop us an email. We will be happy to discuss your copper terminal copper pin connector requirement and provide a bespoke reliable electrical connection solution. Please be advised that no copper pin production is too big or small for us.
                    </div>
                </div>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={Image5} alt='copper contact pin manufacturer in united kingdom' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={Image6} alt='Copper contact pin connectors and terminals for robost electric connection' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={Image7} alt='bespoke tin plated copper terminal pin for excellent electricity conduction' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={Image8} alt='Tin plated copper pins made of copper for better electricity conduction. ' />
                    </div>
                </div>
            </div>
            <div className='MainDiv5'>
                <div className='insideDiv2'>
                    <img src={Image1} alt='' />
                </div>
                <div className='insideDiv3'>
                    <img src={Image2} alt='' />
                </div>
                <div className='insideDiv4'>
                    <img src={Image3} alt='' />
                </div>
            </div>
            <Footer />
        </div>
    );
};


export default CopperPin;