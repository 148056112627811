import React from 'react';
import './Category.css';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ImageElectrical from '../../images/electrical-brass-pin-terminal-connectors-supplier.webp'
import ImagebrassPin1 from '../../images/high-quality-pcb-brass-terminal-pins-electrical-application.webp'
import ImageBrassPin2 from '../../images/round-head-brass-pin-connector-precisionly-produced.webp'
import ImageBrassPin3 from '../../images/reliable-electric-sockets-brass-pin-manufacturer-in-birmingham-uk.webp'
import ImageBrassPin4 from '../../images/gold-plated-brass-pin-flat-head-with-tin-surface.webp'
import ImageBrassPin5 from '../../images/industrial-brass-pins-terminal-darlasotn-smith-ltd.webp'
import Image7 from '../../images/copper-pin connectors-pin-terminal-electrical-pin-connectorpin-terminals.webp.webp';
import Image8 from '../../images/copper-pin-terminal-connector-electric-terminals.webp';

const BrassPin = () => {
    const dummyCategories = [
        { name: 'Aluminium Pin', image: 'https://placekitten.com/102/100', link: 'aluminium-pin-terminal-connector-pins-manufacturer-in-uk' },
        { name: 'Banjo Bolts', image: 'https://placekitten.com/103/100', link: 'hydraulics-banjo-bolts-manufacturer-ss-banjo-bolt-supplier-aluminium-banjo-in-uk' },
        { name: 'Copper Pin', image: 'https://placekitten.com/103/100', link: 'electrical-copper-pin-terminals-copper-connector-pin-bespoke-contact-pins-manufacturer-in-uk' },
        { name: 'Copper Washer', image: 'https://placekitten.com/103/100', link: 'copper-washer-crush-washers-plumbing-washers-copper-gasket-hvac-washers-manufacturer-in-uk' },
        { name: 'Terminal Pin', image: 'https://placekitten.com/103/100', link: 'terminal-pin-terminal-connector-pins-manufacturer-in-uk' },
        { name: 'Nylon Bush', image: 'https://placekitten.com/103/100', link: 'nylon-bushes-bearings-nylone-bushings-flanged-bearning-manufacturer-in-uk' },
        // Add more categories as needed
    ];
    return (
        <div>
            <Header />
            <div className='button-grid'>
                {dummyCategories.map((category, index) => (
                    <Link key={category.name} to={`/${category.link}`} >
                        <button className='button CategoryButton'>{category.name}</button>
                    </Link>
                ))}
            </div>
            <div className='Main'>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={ImageElectrical} alt='round head brass pin terminal for high electric conduction with gold plating manufacturered on CNC machine' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={ImagebrassPin1} alt='precisely manufacturered long brass PCB terminal pin with tin plating' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={ImageBrassPin2} alt='cnc manufacturered brass pins made of hard brass with tin plating for better electric current' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={ImageBrassPin3} alt='robotst brass pin manufacturered on CNC machine in birmingham for electrical application' />
                    </div>
                </div>
                <div className='MainDiv3'>
                    <div>
                        Bespoke Brass Pin Terminals  / Manufacturer
                        As the premier Brass Pin Manufacture in the UK, Darlaston Smith Ltd stands out as a versatile supplier catering to both small and large batches. We take great pride in being a trusted Brass Pin manufacturer, offering a wide range of pin terminal connectors, including high-quality brass pin terminals. Our expertise in this field allows us to deliver tailored solutions for various sizes and specifications, making us the go-to copper pin, brass pin, terminal pins supplier for electrical, automotive and industrial applications. With our extensive experience, we have earned a solid reputation for excellence in providing reliable and durable all type of pins including brass pin, copper pin, copper pin terminals, terminal pin, lug pins, brass escutcheon pin, PCB Mounts, brass electrical plug pin manufacturer or terminal pins. With CNC manufacturing facilities, our commitment to quality and attention to detail ensures optimal performance even in the most demanding environments.
                        Reliability and durability are crucial in pin terminal connectors; therefore, we, as a bespoke brass pin manufacturer, have invested a significant amount of time and resources in perfecting our manufacturing processes. We are committed to sourcing high-quality materials, as we understand that the performance of terminal pins heavily depends on both the quality of the materials used and the precision of the manufacturing techniques employed. Our team, consisting of skilled engineers and technicians, meticulously carries out each step of the production process to ensure that our connector pins meet the highest standards in terms of quality and performance. Our pin surface are finished to desired plating that includes gold plating, tin plating, silver plating etc.
                        Choosing us as your trusted partner for pin terminal requirements is a wise decision. We possess the knowledge, skills, and resources to provide top-notch solutions that exceed expectations. Experience the difference firsthand and empower your electronic applications with our superior copper pin terminal connectors, including brass pin, brass tiller pin, hard brass bridge pin, brass hinges pin, copper pin, connector copper head pins and terminals. We stands out in the competition for brass or copper pin terminal connectors. With our commitment to quality, extensive experience, and dedication to customer satisfaction, we are confident in meeting and exceeding your expectations. Choose us as your trusted partner and let us provide the perfect pin terminal solution for your specific needs.

                    </div>
                </div>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={ImageBrassPin4} alt='high quality brass pin terminal connector with gold plated surface for better electricity conduction' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={ImageBrassPin5} alt='micro drilled industrial brass pin terminal gold plated bespoke brass pin darlaston smith ltd' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={Image7} alt='' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={Image8} alt='' />
                    </div>
                </div>
            </div>
            <div className='MainDiv5'>
                <div className='insideDiv2'>
                    <img src={ImageElectrical} alt='' />
                </div>
                <div className='insideDiv3'>
                    <img src={ImagebrassPin1} alt='' />
                </div>
                <div className='insideDiv4'>
                    <img src={ImageBrassPin2} alt='' />
                </div>
            </div>
            <Footer />
        </div>
    );
};


export default BrassPin;