import React from 'react';
import './Category.css';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Image1 from '../../images/1-copper-crush-washer-gasket-washer-sealing-washer-darlastn-smith-ltd.webp';
import Image2 from '../../images/2-copper-sealing-washers-plumbing-washers-automotive-washers-electrical-washers.webp';
import Image3 from '../../images/3-copper-washer-soft-copper-washer-crush-washer-electroplated-copper-washer.webp';
import Image4 from '../../images/4-Copper-gasket-washer-hvac-washers-supplier-in-uk-bespoke-supplier.webp';
import Image5 from '../../images/5-copper-electroplated-washer-pure-copper-crush-washer-high-temperature-copper-washers.webp';
import Image6 from '../../images/6-copper-washer-hvac-washer-plumbing-washers-automotive-washers-electrical-washers.webp';
import Image7 from '../../images/7-copper-washer-sealing-washers-copper-crush-washer-supplier-in-uk.webp';
import Image8 from '../../images/8-Copper-washer-supplier-soft-crush-washer-oil-leak-washers-bung-plug-washers.webp';

const CopperWasher = () => {
    const dummyCategories = [
        { name: 'Brass Pin', image: 'https://placekitten.com/100/100', link: 'brass-micro-pin-terminal-pins-electrical-connector-pin-manufacturer-in-birmingham-united-kingdom-uk' },
        { name: 'Aluminium Pin', image: 'https://placekitten.com/102/100', link: 'aluminium-pin-terminal-connector-pins-manufacturer-in-uk' },
        { name: 'Banjo Bolts', image: 'https://placekitten.com/103/100', link: 'hydraulics-banjo-bolts-manufacturer-ss-banjo-bolt-supplier-aluminium-banjo-in-uk' },
        { name: 'Copper Pin', image: 'https://placekitten.com/103/100', link: 'electrical-copper-pin-terminals-copper-connector-pin-bespoke-contact-pins-manufacturer-in-uk' },
        { name: 'Terminal Pin', image: 'https://placekitten.com/103/100', link: 'terminal-pin-terminal-connector-pins-manufacturer-in-uk' },
        { name: 'Nylon Bush', image: 'https://placekitten.com/103/100', link: 'nylon-bushes-bearings-nylone-bushings-flanged-bearning-manufacturer-in-uk' },

        // Add more categories as needed
    ];
    return (
        <div>
            <Header />
            <div className='button-grid'>
                {dummyCategories.map((category, index) => (
                    <Link key={category.name} to={`/${category.link}`} >
                        <button className='button CategoryButton'>{category.name}</button>
                    </Link>
                ))}
            </div>
            <div className='Main'>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={Image1} alt='Top-Quality Copper Washers  and copper gaskets for Leakproof Performance in automobile application' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={Image2} alt='Bespoke Copper Sealing Washers - Versatile and Reliable Plumbing, Automotive, and Electrical Washers for Superior Seals.' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={Image3} alt='Copper crush washers for fire proof applications. Multi purpose copper washers for excellent seals.' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={Image4} alt='Copper Gasket Washer HVAC Supplier UK - Bespoke High-Quality Crush Washer Image' />
                    </div>
                </div>
                <div className='MainDiv3'>
                    <div>
                        We take pride in being a leading supplier and manufacturer of a diverse range of copper washers, designed to meet the specific needs of various industrial environments. Within our selection, you will find our top-of-the-line "made to order" copper washers, carefully crafted to meet your unique specifications. These special copper washers are manufactured in a variety of types, including copper sealing washers, crush washers, anti-vibrant washers, high-temperature washers, corrosion-resistant washers, and HVAC washers suitable for heating, ventilation, and air conditioning applications. Our copper crush washers, in particular, provide exceptional sealing properties, ensuring reliable connections in critical systems.
                        Whether you require bonded sealing washers or copper seal washers, our inventory has you covered. Our copper sealing washers are meticulously engineered and tested to conform to superior performance and longevity standards. With their excellent sealing capabilities, these washers are ideal for plumbing, automotive, electrical, as well as HVAC applications.
                        You can trust in our expertise and depend on our comprehensive range of copper washers to meet your diverse requirements. Experience the difference with our high-quality copper washers that deliver unmatched reliability and sealing performance.
                        Here are some of the benefits our copper washer, including copper crush sealing washer, copper crush washer, copper sealing washer, bonded sealing washer, copper sealing washers, and copper seal washers, provide:
                        Dimensional Accuracy: Our custom-engineered washers, including sealing washers, copper crush washers, copper washers, bonded sealing washers, copper sealing washers, and copper seal washers, are meticulously calibrated to meet the most demanding technical specifications, ensuring a seamless integration into your specialized applications.
                        Material Selection Expertise: We use high-grade alloys and specialized composites to create washers, including sealing washers, copper crush washers, copper washers, bonded sealing washers, copper sealing washers, and copper seal washers, with optimal mechanical properties for enhanced performance and longevity.
                        Advanced Coating Technologies: Our washers, including sealing washers, copper crush washers, copper washers, bonded sealing washers, copper sealing washers, and copper seal washers, are treated with state-of-the-art coating technologies, providing superior corrosion resistance and extending the operational life of your assemblies.
                        Application-Specific Designs: Our custom-engineered washers, including sealing washers, copper crush washers, copper washers, bonded sealing washers, copper sealing washers, and copper seal washers, are tailored solutions designed to meet the unique demands of diverse applications, delivering optimal functionality in the face of technical challenges.
                        Catalogue of Technical Excellence: Explore our comprehensive catalogue to discover the versatility of our custom-engineered washers, including sealing washers, copper crush washers, copper washers, bonded sealing washers, copper sealing washers, and copper seal washers. We offer a wide range of intricate shapes and precise sizes to find the perfect technical solution for your project.
                        Tailored Solutions, Limitless Engineering Possibilities: We offer fully customised washers, including sealing washers, copper crush washers, copper washers, bonded sealing washers, copper sealing washers, and copper seal washers, to break free from standardisation. With custom sizes and configurations, we unlock limitless engineering possibilities for your projects. Streamlined Technical Procurement: Our technically sophisticated online platform allows for seamless navigation and prompt delivery of your custom-engineered washers, including sealing washers, copper crush washers, copper washers, bonded sealing washers, copper sealing washers, and copper seal washers, empowering your projects with tailor-made technical excellence.
                        Connect with Technical Precision Today: Ready to bring your unique technical vision to life? Connect with Darlaston Smith Ltd and engage with our technically adept team. Start your journey to superior quality, precision, and personalised reliability in custom-engineered washer manufacturing here, with sealing washers, copper crush washers, copper washers, bonded sealing washers, copper sealing washers, copper washer kit, and copper seal washers conforming to various standards. No quantities is too big or too small for us.
                    </div>
                </div>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={Image5} alt='Copper Electroplated Washer - Pure Copper Crush Washer - High-Temperature Copper Washers' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={Image6} alt='Assortment of copper washers for various applications, including HVAC, plumbing, automotive, and electrical work.' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={Image7} alt='Vibration-dampening copper washers for automotive applications direct from manufacturer' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={Image8} alt='High-quality copper washer and copper gasket for long-lasting performance' />
                    </div>
                </div>
            </div>
            <div className='MainDiv5'>
                <div className='insideDiv2'>
                    <img src={Image1} alt='' />
                </div>
                <div className='insideDiv3'>
                    <img src={Image2} alt='' />
                </div>
                <div className='insideDiv4'>
                    <img src={Image3} alt='' />
                </div>
            </div>
            <Footer />
        </div>
    );
};


export default CopperWasher;