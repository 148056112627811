import React from 'react';
import './Category.css';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Image1 from '../../images/aluminium-industrial-pins-plunger-pin-manufacturer-in-uk.webp';
import Image2 from '../../images/aluminium-precision-pin-manufacturer-in-birmingham.webp';
import Image3 from '../../images/darlaston-smith-ltd-aluminium-pin-manufacturer-in-birmingham.webp';
import Image4 from '../../images/aluminium-connectors-pin-manufacturer-in-uk.webp';
import Image5 from '../../images/aluminium-parts-precision-turned-aluminium-pin-manufacturer-in-uk.webp';
import Image6 from '../../images/precision-aluminium-pin-supplier-aluminium-fastener-supplier.webp';
import Image7 from '../../images/aluminium-fittings-manufacturer-supplier.webp';
import Image8 from '../../images/aluminium-connector-fitting-fastener-manufacturer.webp';

const AluminiumPin = () => {
    const dummyCategories = [
        { name: 'Brass Pin', image: 'https://placekitten.com/100/100', link: 'brass-micro-pin-terminal-pins-electrical-connector-pin-manufacturer-in-birmingham-united-kingdom-uk' },
        { name: 'Banjo Bolts', image: 'https://placekitten.com/103/100', link: 'hydraulics-banjo-bolts-manufacturer-ss-banjo-bolt-supplier-aluminium-banjo-in-uk' },
        { name: 'Copper Pin', image: 'https://placekitten.com/103/100', link: 'electrical-copper-pin-terminals-copper-connector-pin-bespoke-contact-pins-manufacturer-in-uk' },
        { name: 'Copper Washer', image: 'https://placekitten.com/103/100', link: 'copper-washer-crush-washers-plumbing-washers-copper-gasket-hvac-washers-manufacturer-in-uk' },
        { name: 'Terminal Pin', image: 'https://placekitten.com/103/100', link: 'terminal-pin-terminal-connector-pins-manufacturer-in-uk' },
        { name: 'Nylon Bush', image: 'https://placekitten.com/103/100', link: 'nylon-bushes-bearings-nylone-bushings-flanged-bearning-manufacturer-in-uk' },
        // Add more categories as needed
    ];
    return (
        <div>
            <Header />
            <div className='button-grid'>
                {dummyCategories.map((category, index) => (
                    <Link key={category.name} to={`/${category.link}`} >
                        <button className='button CategoryButton'>{category.name}</button>
                    </Link>
                ))}
            </div>
            <div className='Main'>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={Image1} alt='Aluminium Industrial Pins - Plunger Pin Manufacturer in the UK for Reliable Precision Engineering.' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={Image2} alt='Aluminium Precision Pin Manufacturer in Birmingham - Quality pins crafted with precision for industrial applications' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={Image3} alt='Darlaston Smith Ltd - Trusted Aluminium Pin Manufacturer in Birmingham, Delivering Quality and Precision.' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={Image4} alt='Aluminium Connectors and Pins - Leading Manufacturer in the UK for High-Quality Industrial Components.' />
                    </div>
                </div>
                <div className='MainDiv3'>
                    <div>
                        We offer you one stop solution for precision micro aluminium pin! We pride ourselves on being the leading experts in small and medium batch manufacturing, supplying, and consulting. When it comes to aluminium pins, we've got you covered with our versatile hardware pins, ranging from 10mm to 35mm in diameter. No matter what your unique requirements are, whether it's for hardware or electronic components, our dedicated team is committed to delivering the highest quality aluminium pin.
                        Having issues with your current parts or redesigning your product? Don't worry, our skilled team is here to help. We leverage our expertise in aluminium pin design, engineering, and tool design to provide cost-effective solutions tailored to your specific needs. Say goodbye to defects and benefit from our extensive knowledge.
                        Our micro aluminium pins, also known as aluminium rolling pin, are perfect for a wide range of electronic device applications. Choose from our bright coloured anodized and nickel-plated options to enhance solderability and prolong the shelf life of your products.
                        Elevate your projects with our precision-engineered micro aluminium pins. Contact us today for tailored solutions that will take your projects to new heights of excellence. Our high-quality aluminium pins, which are synonymous with aluminium pin type lugs. Our standard aluminium head nose pin for mask are perfect for various applications, from hardware to fasteners. Crafted with expertise, our pins feature burr-free surfaces and fine tips, ensuring seamless insertion and optimal performance.

                    </div>
                </div>
                <div className='MainDiv'>
                    <div className='insideDiv1'>
                        <img src={Image5} alt='Aluminium Parts - Precision Turned Aluminium Pin Manufacturer in the UK for Exceptional Quality' />
                    </div>
                    <div className='insideDiv2'>
                        <img src={Image6} alt='Precision Aluminium Pin Supplier - Your Trusted Source for High-Quality Aluminium Fasteners' />
                    </div>
                    <div className='insideDiv3'>
                        <img src={Image7} alt='Aluminium Fittings - Leading Manufacturer and Supplier of High-Quality Industrial Components.' />
                    </div>
                    <div className='insideDiv4'>
                        <img src={Image8} alt='Aluminium Connector, Fitting, and Fastener Manufacturer - Trusted Source for High-Quality Industrial Components.' />
                    </div>
                </div>
            </div>
            <div className='MainDiv5'>
                <div className='insideDiv2'>
                    <img src={Image1} alt='' />
                </div>
                <div className='insideDiv3'>
                    <img src={Image2} alt='' />
                </div>
                <div className='insideDiv4'>
                    <img src={Image3} alt='' />
                </div>
            </div>
            <Footer />
        </div>
    );
};


export default AluminiumPin;