import React, { useState, useEffect } from 'react';
import './slideShow.css';
import image1 from '../images/home1.webp';
import image2 from '../images/Home2.webp';
import image3 from '../images/home 3.webp';

const Slideshow = () => {
    const [slideIndex, setSlideIndex] = useState(1);

    useEffect(() => {
        showSlides(slideIndex);
    }, [slideIndex]);

    const plusSlides = (n) => {
        setSlideIndex((prevIndex) => prevIndex + n);
    };

    const currentSlide = (n) => {
        setSlideIndex(n);
    };

    const showSlides = (n) => {
        let i;
        const slides = document.getElementsByClassName('mySlides');
        const dots = document.getElementsByClassName('dot');
        if (n > slides.length) setSlideIndex(1);
        if (n < 1) setSlideIndex(slides.length);
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = 'none';
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(' active', '');
        }
        slides[slideIndex - 1].style.display = 'block';
        dots[slideIndex - 1].className += ' active';
    };

    return (
        <div className="slideshow-container">
            <div className="mySlides fade">
                <div className="numbertext">1 / 3</div>
                <img src={image1} style={{ width: '100%' }} alt="Slide 1" />
                <div className="text">Caption Text</div>
            </div>

            <div className="mySlides fade">
                <div className="numbertext">2 / 3</div>
                <img src={image2} style={{ width: '100%' }} alt="Slide 2" />
                <div className="text">Caption Two</div>
            </div>

            <div className="mySlides fade">
                <div className="numbertext">3 / 3</div>
                <img src={image3} style={{ width: '100%' }} alt="Slide 3" />
                <div className="text">Caption Three</div>
            </div>

            <a className="prev" onClick={() => plusSlides(-1)}>
                ❮
            </a>
            <a className="next" onClick={() => plusSlides(1)}>
                ❯
            </a>

            <div style={{ textAlign: 'center' }}>
                <span className="dot" onClick={() => currentSlide(1)}></span>
                <span className="dot" onClick={() => currentSlide(2)}></span>
                <span className="dot" onClick={() => currentSlide(3)}></span>
            </div>
        </div>
    );
};

export default Slideshow;
